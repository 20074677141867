import React, { memo, useMemo, useState } from "react";
import moment from "moment";
import { Divider, Header, List } from "semantic-ui-react";

import ConversationItem from "./ConversationItem/ConversationItem";

const ConversationsList = ({
  activeSessionId,
  conversationsMap,
  onConversationSelected,
  showMissed,
}) => {
  const conversations = useMemo(() => {
    let preparedConversations = Object.entries(conversationsMap).sort(
      ([, valueFirst], [, valueSecond]) => {
        const firstTimeString = moment(
          `${valueFirst.date} ${valueFirst.time}`,
          "DD/MM/YYYY HH:mm:ss"
        ).toDate();
        const secondTimeString = moment(
          `${valueSecond.date} ${valueSecond.time}`,
          "DD/MM/YYYY HH:mm:ss"
        ).toDate();

        return new Date(secondTimeString) - new Date(firstTimeString);
      }
    );
    if (!showMissed) {
      preparedConversations = preparedConversations.filter(([, { status }]) => {
        return status !== "Missed";
      });
    }

    return preparedConversations;
  }, [conversationsMap, showMissed]);

  return (
    <div className="conversations-list">
      <Header as="h3">Conversations</Header>
      <Divider />
      <List relaxed>
        {conversations.map(([sessionId, value]) => (
          <ConversationItem
            onClick={() => onConversationSelected(value.userId, sessionId)}
            selected={activeSessionId === sessionId}
            status={value.status}
            key={sessionId}
            date={`${value.date} ${value.time}`}
          />
        ))}
      </List>
    </div>
  );
};

export default memo(ConversationsList);
