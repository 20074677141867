import React from "react";
import {Divider, Icon, List} from "semantic-ui-react";

const marketIndicatorColors = {
  activeMarket: "green",
  notActiveMarket: "grey",
};

const Market = ({
                  activeMarket,
                  marketName,
                  onMarketClick
                }) => {
  let marketStatus = !activeMarket ? marketIndicatorColors.notActiveMarket : marketIndicatorColors.activeMarket;
  return (
    <>
      <List.Item
        onClick={() => onMarketClick(marketName)}
        className={
          "market " +
          (activeMarket ? "selected-market " : " ")
        }
      >
        <List.Content>
          <List.Description as="h4">
            {marketName.toUpperCase()}
            <Icon
              name="circle"
              style={{float: "right"}}
              color={marketStatus}
            />
          </List.Description>
        </List.Content>
      </List.Item>
      <Divider/>
    </>
  );
};

export default React.memo(Market);
