import React, {memo} from "react";
import {Divider, Header, List} from "semantic-ui-react";
import Market from "./Market/Market";

const Markets = ({
                   markets,
                   activeMarketName,
                   onMarketClick,
                 }) => {
  return (<div className="markets">
    <Divider/>
    <Header as="h3">Markets</Header>
    <List relaxed>
      {markets.map(market => (
        <Market
          activeMarket={activeMarketName === market.market}
          marketName={market.market}
          onMarketClick={onMarketClick}
          key={market.market}
        />
      ))}
    </List>
  </div>)
};

export default memo(Markets);
